import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'animate.css'
import '@/assets/css/public.scss'
import App from './App.vue'
import moment from 'moment'
import api from '@/api'
import axios from '@/utils/request'
import $ from 'jquery'
import '@/mock'
const app = createApp(App)

window.$ = $
app.config.globalProperties.$http = axios
moment.locale('zh-cn')
app.config.globalProperties.$moment = moment
app.config.globalProperties.$api = api
app.use(ElementPlus)
app.mount('#app')
