<template>
  <el-config-provider :locale="locale">
    <div id="full-container">
      <div class="full-container-header animate__animated animate__bounceIn">
        <div class="full-container-header-logo">
          <img src="@/assets/img/LFS_logo.png" />
        </div>
        <div class="full-container-header-time">
          <ul class="full-container-header-time-container">
            <li>{{ currentDate.day }}</li>
            <li>{{ currentDate.week }}</li>
            <li>{{ currentDate.time }}</li>
          </ul>
        </div>
      </div>
      <div class="full-container-body">
        <div class="full-container-body-item">
          <fullContainer title="每日情况统计" height="292px">
            <daily :data="totalStatData"></daily>
          </fullContainer>
          <fullContainer title="进出人员统计" height="347px">
            <echartsContainer
              ref="exitChart"
              :option="exitOption"
            ></echartsContainer>
          </fullContainer>
          <fullContainer title="设备启动趋势" height="320px">
            <echartsContainer
              :option="startDeviceTrendOption"
            ></echartsContainer>
          </fullContainer>
        </div>
        <div class="full-container-body-item">
          <fullContainer height="764px">
            <div class="center-top">
              <div class="num-echarts-container">
                <div class="num-echarts-card1">
                  <div class="card1-text">进出人员累计数据</div>
                  <div class="card1-bottom">
                    <numCard
                      :num="totalStatData.total_entry || 0"
                      color="#0da0e0"
                    ></numCard>
                    <span class="card1-bottom-text">人</span>
                  </div>
                </div>
                <div class="num-echarts-card2">
                  <div class="card2-text">设备启动累计数据</div>
                  <div class="card2-bottom">
                    <numCard
                      :num="totalStatData.total_usage || 0"
                      color="#0064da"
                    ></numCard>
                    <span class="card2-bottom-text">次</span>
                  </div>
                </div>
              </div>
              <div class="fjmap">
                <echartsContainer
                  ref="mapCharts"
                  :option="mapOption"
                ></echartsContainer>
                <div class="animation-legend">
                  <img src="./assets/img/red.png" alt />
                </div>
                <div class="map-legend">
                  <div class="map-legend-title">图例</div>
                  <div class="map-legend-item-top">
                    <img src="./assets/img/red.png" alt />
                    <span>10+家</span>
                  </div>
                  <div class="map-legend-item-bottom">
                    <img src="./assets/img/blue.png" alt />
                    <span>1-10家</span>
                  </div>
                </div>
              </div>
            </div>
          </fullContainer>
          <fullContainer
            :title="params.id === 1 ? '室内环境空气质量检测' : '室外空气质量'"
            height="197px"
            class="animate__slideInRight"
            :animate="animate"
          >
            <div class="pie-echarts-container">
              <div class="pie-echarts-item">
                <div
                  class="pie-echarts-animation"
                  style="--color: #c9d552"
                ></div>
                <echartsContainer
                  class="pie-temp"
                  ref="pieChart1"
                  :option="pieOption1"
                ></echartsContainer>
                <div class="pie-echarts-text">温度</div>
              </div>
              <div class="pie-echarts-item">
                <div
                  class="pie-echarts-animation"
                  style="--color: #26fa37"
                ></div>
                <echartsContainer
                  class="pie-pm2.5"
                  ref="pieChart2"
                  :option="pieOption2"
                ></echartsContainer>
                <div class="pie-echarts-text">PM2.5</div>
              </div>
              <div class="pie-echarts-item">
                <div
                  class="pie-echarts-animation"
                  style="--color: #1fddff"
                ></div>
                <echartsContainer
                  class="pie-hum"
                  ref="pieChart3"
                  :option="pieOption3"
                ></echartsContainer>
                <div class="pie-echarts-text">湿度</div>
              </div>
            </div>
          </fullContainer>
        </div>
        <div class="full-container-body-item">
          <fullContainer title="人员信息分析" height="292px">
            <div class="infor-echarts-container">
              <div class="infor-echarts-left">
                <div class="left-container">
                  <echartsContainer
                    ref="inforChart1"
                    :option="infor3dOption"
                  ></echartsContainer>
                  <div class="content">
                    <span class="text">年龄</span>
                  </div>
                </div>
              </div>
              <div class="infor-echarts-right">
                <div class="infor-right-man">
                  <echartsContainer
                    ref="inforChart2"
                    :option="InfroSecOption1"
                  ></echartsContainer>
                  <div class="pic">
                    <img src="./assets/img/man.png" alt />
                  </div>
                  <div class="content">
                    <span class="text">男性</span>
                  </div>
                </div>
                <div class="infor-right-women">
                  <echartsContainer
                    ref="inforChart2"
                    :option="InfroSecOption2"
                  ></echartsContainer>
                  <div class="pic">
                    <img src="./assets/img/women.png" alt />
                  </div>
                  <div class="content">
                    <span class="text">女性</span>
                  </div>
                </div>
              </div>
            </div>
          </fullContainer>
          <fullContainer title="用户使用详情" height="347px">
            <scrollTable
              :columns="userUsageDetailColumns"
              :data="userUsageDetailData"
              :height="275"
            ></scrollTable>
          </fullContainer>
          <fullContainer title="设备使用详情" height="230px">
            <scrollTable
              :columns="deviceUsageDetailColumns"
              :data="deviceUsageDetailData"
              :height="225"
            ></scrollTable>
          </fullContainer>
        </div>
      </div>
      <div class="full-container-animation"></div>
      <canvas id="myCanvas"></canvas>
    </div>
  </el-config-provider>
</template>

<script>
import scrollTable from "./components/scroll-table";
import fullContainer from "./components/full-container-item";
import echartsContainer from "./components/echarts-container";
import daily from "./components/daily.vue";
import numCard from "./components/num-card";
import zhCn from "element-plus/es/locale/lang/zh-cn";

import * as echarts from "echarts";
import "animate.css";
import { mapData } from "./common/mapData";
let angle = 0; //角度，用来做简单的动画效果的
export default {
  components: { fullContainer, echartsContainer, numCard, scrollTable, daily },
  data() {
    return {
      params: {
        id: 1,
      },
      dateTimer: null,
      animateTimer: null,
      animate: true,
      // 用户使用详情数据
      userUsageDetailColumns: [
        { label: "用户", prop: "userName", width: "110px", align: "center" },
        {
          label: "使用时间段",
          prop: "timeRange",
          width: "160px",
          align: "center",
        },
        { label: "使用时长", prop: "duration" },
        { label: "使用日期", prop: "useDate", width: "140px", align: "center" },
      ],
      deviceUsageDetailColumns: [
        {
          label: "设备名称",
          prop: "device_name",
          width: "180px",
          align: "center",
        },
        { label: "设备ID", prop: "device_id", width: "140px", align: "center" },
        { label: "累计使用时长", prop: "use_time", align: "center" },
      ],
      totalStatData: {}, // 人员/设备情况统计数据
      airQualityData: {}, // 环境空气质量数据
      entryStatData: {}, // 近24小时人员进出情况数据
      deviceUsageStatData: {}, // 近24小时设备使用情况数据
      userUsageDetailData: [], // 用户使用详情数据
      deviceUsageDetailData: [], // 设备使用详情数据
      userInfoStatData: {}, // 人员信息分析
      locale: zhCn,
      currentDate: {
        day: "",
        week: "",
        time: "",
      },
      //设备分类概况
      startDeviceTrendOption: {},
      pieOption1: {},
      pieOption2: {},
      pieOption3: {},
      infor3dOption: {},
      InfroSecOption1: {},
      InfroSecOption2: {},
      exitOption: {},
      mapOption: {},
    };
  },
  methods: {
    // 人员信息分析 扇形 男
    getInfroSecOption1(sexData) {
      let sumData = sexData.female + sexData.male;
      let maleValue = isNaN((sexData.male / sumData) * 100)
        ? 0
        : ((sexData.male / sumData) * 100).toFixed(0);
      this.InfroSecOption1 = {
        tooltip: {},
        color: ["#264BF3", "#24314D", "transparent"],
        series: [
          {
            type: "pie",
            radius: ["60%", "100%"],
            avoidLabelOverlap: false,
            startAngle: 140,
            center: ["50%", "45%"],
            label: {
              show: true,
              position: "inner",
              formatter: maleValue + "%",
              rotate: 30,
              textStyle: {
                color: "#fff",
                fontSize: 10,
                fontWeight: 700,
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 11,
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: maleValue,
                tooltip: {
                  formatter: function (a) {
                    return `${a.marker} value: ${a.value}`;
                  },
                },
              },
              {
                value: 100 - maleValue,
                tooltip: {
                  formatter: function (a) {
                    return "";
                  },
                },
                label: {
                  show: false,
                },
              },
              {
                value: 260,
                name: "",
                tooltip: {
                  formatter: function (a) {
                    return "";
                  },
                },
                label: {
                  show: false,
                },
              },
            ],
          },
        ],
      };
    },
    // 人员信息分析 扇形 女
    getInfroSecOption2(sexData) {
      let sumData = sexData.female + sexData.male;
      let femaleValue = isNaN((sexData.female / sumData) * 100)
        ? 0
        : ((sexData.female / sumData) * 100).toFixed(0);
      this.InfroSecOption2 = {
        tooltip: {},
        color: ["rgba(251, 90, 210, 1)", "#24314D", "transparent"],
        series: [
          {
            type: "pie",
            radius: ["60%", "100%"],
            avoidLabelOverlap: false,
            startAngle: 140,
            center: ["50%", "45%"],
            label: {
              show: true,
              position: "inside",
              formatter: femaleValue + "%",
              rotate: 18,
              textStyle: {
                color: "#fff",
                fontSize: 10,
                fontWeight: 800,
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 11,
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: femaleValue,
                tooltip: {
                  formatter: function (a) {
                    return `${a.marker} value: ${a.value}`;
                  },
                },
              },
              {
                value: 100 - femaleValue,
                tooltip: {
                  formatter: function (a) {
                    return "";
                  },
                },
                label: {
                  show: false,
                },
              },
              {
                value: 260,
                name: "",
                tooltip: {
                  formatter: function (a) {
                    return "";
                  },
                },
                label: {
                  show: false,
                },
              },
            ],
          },
        ],
      };
    },
    // 人员信息分析 3d
    getInfor3dOption(inforData) {
      // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
      function getParametricEquation(
        startRatio,
        endRatio,
        isSelected,
        isHovered,
        k,
        h
      ) {
        // 计算
        let midRatio = (startRatio + endRatio) / 2;

        let startRadian = startRatio * Math.PI * 2;
        let endRadian = endRatio * Math.PI * 2;
        let midRadian = midRatio * Math.PI * 2;

        // 如果只有一个扇形，则不实现选中效果。
        if (startRatio === 0 && endRatio === 1) {
          isSelected = false;
        }

        // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
        k = typeof k !== "undefined" ? k : 1 / 3;

        // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
        let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
        let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

        // 计算高亮效果的放大比例（未高亮，则比例为 1）
        let hoverRate = isHovered ? 1.05 : 1;

        // 返回曲面参数方程
        return {
          u: {
            min: -Math.PI,
            max: Math.PI * 3,
            step: Math.PI / 32,
          },

          v: {
            min: 0,
            max: Math.PI * 2,
            step: Math.PI / 20,
          },

          x: function (u, v) {
            if (u < startRadian) {
              return (
                offsetX +
                Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
              );
            }
            if (u > endRadian) {
              return (
                offsetX +
                Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
              );
            }
            return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
          },

          y: function (u, v) {
            if (u < startRadian) {
              return (
                offsetY +
                Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
              );
            }
            if (u > endRadian) {
              return (
                offsetY +
                Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
              );
            }
            return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
          },

          z: function (u, v) {
            if (u < -Math.PI * 0.5) {
              return Math.sin(u);
            }
            if (u > Math.PI * 2.5) {
              return Math.sin(u) * h * 0.1;
            }
            return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
          },
        };
      }

      // 生成模拟 3D 饼图的配置项
      let getPie3D = (pieData, internalDiameterRatio) => {
        let series = [];
        let sumValue = 0;
        let startValue = 0;
        let endValue = 0;
        let legendData = [];
        let k =
          typeof internalDiameterRatio !== "undefined"
            ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
            : 1 / 3;
        // 为每一个饼图数据，生成一个 series-surface 配置
        for (let i = 0; i < pieData.length; i++) {
          sumValue += pieData[i].value;

          let seriesItem = {
            name:
              typeof pieData[i].name === "undefined"
                ? `series${i}`
                : pieData[i].name,
            type: "surface",
            parametric: true,
            wireframe: {
              show: false,
            },
            pieData: pieData[i],
            pieStatus: {
              selected: false,
              hovered: false,
              k: k,
            },
          };

          if (typeof pieData[i].itemStyle != "undefined") {
            let itemStyle = {};

            typeof pieData[i].itemStyle.color != "undefined"
              ? (itemStyle.color = pieData[i].itemStyle.color)
              : null;
            typeof pieData[i].itemStyle.opacity != "undefined"
              ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
              : null;

            seriesItem.itemStyle = itemStyle;
          }
          series.push(seriesItem);
        }

        // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
        // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
        let linesSeries = [];
        for (let i = 0; i < series.length; i++) {
          endValue = startValue + series[i].pieData.value;

          series[i].pieData.startRatio = startValue / sumValue;
          series[i].pieData.endRatio = endValue / sumValue;
          series[i].parametricEquation = getParametricEquation(
            series[i].pieData.startRatio,
            series[i].pieData.endRatio,
            false,
            false,
            k,
            series[i].pieData.value
          );

          startValue = endValue;
          let midRadian =
            (series[i].pieData.endRatio + series[i].pieData.startRatio) *
            Math.PI;
          let posX = Math.cos(midRadian) * (1 + Math.cos(Math.PI / 2));
          let posY = Math.sin(midRadian) * (1 + Math.cos(Math.PI / 2));
          let posZ = Math.log(Math.abs(series[i].pieData.value + 1)) * 0.1;
          let flag =
            (midRadian >= 0 && midRadian <= Math.PI / 2) ||
            (midRadian >= (3 * Math.PI) / 2 && midRadian <= Math.PI * 2)
              ? 1
              : -1;
          let color = pieData[i].itemStyle.color;
          let endPosArr = [
            posX * 1.8 + i * 0.1 * flag + (flag < 0 ? -0.5 : 0),
            posY * 1.8 + i * 0.1 * flag + (flag < 0 ? -0.5 : 0),
            posZ * 2,
          ];
          linesSeries.push(
            {
              type: "line3D",
              lineStyle: {
                color: color,
              },
              // data: [[posX, posY, posZ], endPosArr]
            },
            {
              type: "scatter3D",
              label: {
                show: false,
                textStyle: {
                  color: color,
                  fontSize: 14,
                  backgroundColor: "transparent",
                },
                formatter: "{b}",
              },
              symbolSize: 0,
              animation: false,
              data: [{ name: series[i].pieData.value, value: endPosArr }],
            }
          );
          legendData.push(series[i].name);
        }
        series = series.concat(linesSeries);

        // 补充一个透明的圆环，用于支撑高亮功能的近似实现。
        series.push({
          name: "mouseoutSeries",
          type: "surface",
          parametric: true,
          wireframe: {
            show: false,
          },
          itemStyle: {
            opacity: 0,
          },
          animation: false,
          parametricEquation: {
            u: {
              min: 0,
              max: Math.PI * 2,
              step: Math.PI / 20,
            },
            v: {
              min: 0,
              max: Math.PI,
              step: Math.PI / 20,
            },
            x: function (u, v) {
              return Math.sin(v) * Math.sin(u) + Math.sin(u);
            },
            y: function (u, v) {
              return Math.sin(v) * Math.cos(u) + Math.cos(u);
            },
            z: function (u, v) {
              return Math.cos(v) > 0 ? 0.1 : -0.1;
            },
          },
        });
        // 增加一个2d饼状图，来显示标注线
        series.push({
          name: "年龄占比",
          type: "pie",
          label: {
            show: true,
            position: "outside",
            color: "inherit",
            fontSize: 14,
            fontWeight: 800,
            opacity: 1,
            formatter: (params) => {
              return Math.floor((params.value / sumValue) * 100) + "%";
            },
          },
          labelLine: {
            show: true,
            length: 15,
            length2: 15,
            lineStyle: {
              width: 2,
            },
          },
          animation: false,
          data: pieData,
          startAngle: 312,
          clockwise: false,
          radius: ["28%", "40%"],
          center: ["52%", "58%"],
          itemStyle: {
            opacity: 0,
          },
        });

        // 准备待返回的配置项，把准备好的 legendData、series 传入。
        let option = {
          //animation: false,
          legend: {
            top: 27,
            left: 25,
            data: [
              { name: "25岁以下" },
              { name: "25-40岁" },
              { name: "40-55岁" },
              { name: "55-65岁" },
              { name: "65岁以上" },
            ],
            itemGap: 7,
            textStyle: {
              // color: ['#f77b66', '#3edce0', '#f94e76', '#018ef1', '#9e60f9'],
              color: ["#00a1df", "#e14881", "#33d7d9", "#e0886c", "#af64e1"],
              fontSize: "12",
            },
          },
          // tooltip: {
          //   formatter: params => {
          //     if (params.seriesName !== 'mouseoutSeries') {
          //       return `${params.seriesName}<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>${option.series[params.seriesIndex].pieData.value}`;
          //     }
          //   }
          // },
          xAxis3D: {
            min: -1,
            max: 1,
          },
          yAxis3D: {
            min: -1,
            max: 1,
          },
          zAxis3D: {
            min: -1,
            max: 1,
          },
          grid3D: {
            width: "75%",
            height: "75%",
            left: "15%",
            top: "20%",
            show: false,
            boxHeight: 5,
            viewControl: {
              //3d效果可以放大、旋转等，请自己去查看官方配置
              alpha: 40,
              // beta: 40,
              rotateSensitivity: 0,
              zoomSensitivity: 0,
              panSensitivity: 0,
              autoRotate: false,
            },
            //后处理特效可以为画面添加高光、景深、环境光遮蔽（SSAO）、调色等效果。可以让整个画面更富有质感。
            postEffect: {
              //配置这项会出现锯齿，请自己去查看官方配置有办法解决
              enable: true,
              bloom: {
                enable: true,
                bloomIntensity: 0.1,
              },
              SSAO: {
                enable: true,
                quality: "medium",
                radius: 2,
              },
            },
          },
          series: series,
        };
        return option;
      };

      // 传入数据生成 option
      this.infor3dOption = getPie3D(inforData, 0.59);
    },
    // 获取地图
    getMapOption() {
      echarts.registerMap("fjMap", mapData);
      this.mapOption = {
        visualMap: {
          show: false,
          min: 0,
          max: 20,
          realtime: false,
          calculable: true,
          inRange: {
            color: ["#3fb9f8", "#2355ef", "#163de8"],
          },
          calculable: true,
        },
        series: [
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            clip: false,
            symbolSize: (params) => {
              // if (params[0] === 118.060012) {
              //   return 90
              // } else {
              //   return 45
              // }
              return 23;
            },
            zlevel: 1,
            itemStyle: {
              color: (params) => {
                if (params.value[2] >= 10) {
                  return "rgba(204, 56, 62, .3)";
                } else {
                  return "rgba(2, 56, 204, .3)";
                }
              },
            },
            label: {
              show: true,
              formatter: (params) => {
                if (params.value[0] === 118.060012) {
                  return `''\n${params.name}`;
                }
                if (params.value[2] >= 10) {
                  return `{b|}\n${params.name}`;
                } else {
                  return `{a|}\n${params.name}`;
                }
              },
              rich: {
                a: {
                  width: 27,
                  height: 36,
                  backgroundColor: {
                    image: require("./assets/img/blue.png"),
                  },
                },
                b: {
                  width: 27,
                  height: 36,
                  backgroundColor: {
                    image: require("./assets/img/red.png"),
                  },
                },
                c: {
                  width: 54,
                  height: 72,
                  backgroundColor: {
                    image: require("./assets/img/red.png"),
                  },
                },
              },
            },
            data: [
              { value: [118.091377, 27.654742, 3], visualMap: false }, // 南平市
              { value: [117.330671, 26.60466, 5], visualMap: false }, // 三明市
              { value: [116.68232, 25.602005, 7], visualMap: false }, // 龙岩市
              { value: [119.423935, 27.3025, 11], visualMap: false }, // 宁德市
              { value: [119.127494, 26.36208, 12], visualMap: false }, // 福州市
              { value: [118.835096, 25.737516, 2], visualMap: false }, // 莆田市
              { value: [118.20842, 25.500214, 9], visualMap: false }, // 泉州市
              { value: [118.060012, 24.986588, 10], visualMap: false }, // 厦门市
              { value: [117.399903, 24.661297, 2], visualMap: false }, // 漳州市
            ],
          },
          {
            type: "map",
            map: "fjMap",
            zoom: 1.1,
            silent: true,
            aspectScale: 0.9,
            z: 2,
            label: {
              show: true, // 显示地图文字标签
              fontSize: 16,
              fontStyle: "italic",
              fontWeight: 700,
              color: "#fff",
            },
            data: [
              { name: "南平市", value: 3 },
              { name: "三明市", value: 5 },
              { name: "龙岩市", value: 7 },
              { name: "宁德市", value: 11 },
              { name: "福州市", value: 12 },
              { name: "莆田市", value: 2 },
              { name: "泉州市", value: 9 },
              { name: "厦门市", value: 10 },
              { name: "漳州市", value: 2 },
            ],
            itemStyle: {
              shadowOffsetX: 1,
              shadowOffsetY: 5,
              shadowColor: "#155db3",
              shadowBlur: 3,
              borderColor: "#09d9fb",
              borderWidth: 1,
            },
          },
        ],
        geo: {
          type: "map",
          map: "fjMap",
          zoom: 1.1,
          left: 105,
          top: 95,
          aspectScale: 0.9,
          silent: true,
          itemStyle: {
            color: "#0d3cc5",
            shadowColor: "#08256c",
            shadowOffsetX: 10,
            shadowOffsetY: 5,
          },
        },
      };
    },
    // 进出人员统计
    getExitChart() {
      const newXData = this.entryStatData.xData.map(
        (item) => (item = item + "点")
      );
      const maxNum = Math.floor(Math.max(...this.entryStatData.yData) * 1.5);
      this.exitOption = {
        title: {
          textStyle: {
            align: "center",
            color: "#CAFFFD",
            fontSize: 12,
          },
          top: "0%",
          left: "center",
        },
        backgroundColor: "",
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: 60,
          left: 30,
          right: 40,
          bottom: 10,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          // boundaryGap: false, //坐标轴两边留白
          data: newXData,
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
            //	margin:15,
            textStyle: {
              color: "#fff",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 12,
            },
            formatter: function (params) {
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 4; //一行显示几个字
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  var start = p * provideNumber;
                  var end = start + provideNumber;
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }
              return newParamsName;
            },
            //rotate:50,
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: true,
            length: 4,
            inside: true,
            lineStyle: {
              color: "#fff",
            },
          },
          axisLine: {
            //坐标轴轴线相关设置
            lineStyle: {
              color: "#fff",
              opacity: 0.6,
            },
          },
          splitLine: {
            //坐标轴在 grid 区域中的分隔线。
            show: false,
            lineStyle: {
              color: "#E5E9ED",
              // 	opacity:0.1
            },
          },
        },
        yAxis: [
          {
            type: "value",
            name: "人",
            min: 0,
            max: maxNum,
            splitNumber: 7,
            nameTextStyle: {
              fontSize: 12,
              padding: [0, 0, 10, 0],
            },
            axisLabel: {
              textStyle: {
                color: "#fff",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#CAFFFD",
                opacity: 0.3,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "solid",
                color: "#8EEEFF",
                opacity: 0.2,
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            smooth: true,
            symbol: "",
            symbolSize: 0,
            lineStyle: {
              color: "#9999FF",
              width: 1,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: "rgba(105, 60, 254, 0.1)",
                },
                {
                  offset: 1,
                  color: "rgba(105, 60, 254, 1)",
                },
              ]),
            },
            data: this.entryStatData.yData,
          },
        ],
      };
    },
    // 室内环境空气质量检测 温度
    getPieOption1(temp) {
      let value = temp;
      let maxvalue = 40;
      let percent = (value / maxvalue) * 100;
      percent = percent.toFixed(0);
      let option = {
        animation: false,
        title: {
          text: "{a|" + value + "}\n{c|℃}",
          x: "center",
          y: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: 18,
                color: "#c2c66e",
                fontWeight: 700,
              },
              c: {
                fontSize: 12,
                color: "rgba(255,255,255,.5)",
                fontWeight: 700,
              },
            },
          },
        },
        polar: [
          {
            radius: ["60%", "45%"],
            center: ["50%", "50%"],
          },
        ],

        angleAxis: {
          max: maxvalue,
          show: false,
          inverse: false,
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },

        series: [
          //小弧线
          {
            name: "ring5",
            type: "custom",
            z: 1,
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r:
                    (Math.min(api.getWidth(), api.getHeight()) / 3.3) *
                    0.6 *
                    1.6,
                  // startAngle: (0+angle) * Math.PI / 180,
                  // endAngle: (90+angle) * Math.PI / 180
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180,
                },
                style: {
                  stroke: "rgb(53,53,53)",
                  fill: " transparent",
                  lineWidth: 2,
                },
                silent: true,
              };
            },
            data: [0],
          },
          //极坐标柱状图
          {
            name: "",
            type: "bar",
            roundCap: true,
            barWidth: 4,
            showBackground: true,
            stack: "a",
            backgroundStyle: {
              color: "rgba(66, 66, 66, 0)",
            },
            data: [value, 0],
            coordinateSystem: "polar",

            itemStyle: {
              color: "#c9d552",
              shadowBlur: 8,
              shadowColor: "rgba(211,145,28,1)",
            },
          },

          //分割刻度
          {
            type: "gauge",
            radius: "80%",
            clockwise: false,
            startAngle: "90",
            endAngle: "89",
            splitNumber: 36,
            detail: {
              offsetCenter: [0, -10],
              formatter: " ",
            },
            pointer: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: [
                  [0, "#c9d552"],
                  [percent / 100, "#c9d552"],
                  [1, "#10134c"],
                ],
                width: 6,
              },
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: "rgb(99,99,99)",
                width: 2, //刻度线的宽度
              },
              length: 1, //刻度线的长度
              splitNumber: 5,
            },
            splitLine: {
              show: true,
              distance: -7,
              length: 9,
              lineStyle: {
                width: 3,
                color: "#090a51",
              },
            },
            axisLabel: {
              show: false,
            },
          },

          //仪表盘，用于显示小刻度圈
          {
            name: "统计",
            type: "gauge",
            splitNumber: 8, //刻度数量
            min: 0,
            max: 100,
            radius: "58%", //图表尺寸
            center: ["50%", "50%"],
            startAngle: 90,
            endAngle: -269.9999,
            axisLine: {
              show: false,
              lineStyle: {
                width: 0,
                shadowBlur: 0,
                color: [
                  [0, "rgb(99,99,99)"],
                  [1, "rgb(99,99,99)"],
                ],
              },
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: "rgb(99,99,99)",
                width: 2, //刻度线的宽度
              },
              length: 1, //刻度线的长度
              splitNumber: 5,
            },
            splitLine: {
              show: true,
              length: 0,
              lineStyle: {
                color: "rgb(99,99,99)",
              },
            },
            axisLabel: {
              show: false,
            },
            // pointer: { //仪表盘指针
            //     show: 0,
            // },
            detail: {
              borderColor: "#fff",
              shadowColor: "#fff", //默认透明
              shadowBlur: 1,
              offsetCenter: [0, "0%"], // x, y，单位px
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                color: "0",
                fontSize: 25,
              },
              formatter: "{value}",
            },
            // data: [{
            //     name: "",
            //     value: 0
            // }]
          },

          //外圈波纹效果
        ],
      };
      this.pieOption1 = option;
    },
    // 室内环境空气质量检测 PM2.5
    getPieOption2(pm) {
      let value = pm;
      let maxvalue = 40;
      let percent = (value / maxvalue) * 100;
      percent = percent.toFixed(0);
      let option = {
        animation: false,
        title: {
          text: "{a|" + value + "}\n{c|ug/m³}",
          x: "center",
          y: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: 18,
                color: "#43d34e",
                fontWeight: 700,
              },
              c: {
                fontSize: 12,
                color: "rgba(255,255,255,.5)",
                fontWeight: 700,
              },
            },
          },
        },
        polar: [
          {
            radius: ["60%", "45%"],
            center: ["50%", "50%"],
          },
        ],

        angleAxis: {
          max: maxvalue,
          show: false,
          inverse: false,
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },

        series: [
          //小弧线
          {
            name: "ring5",
            type: "custom",
            z: 1,
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r:
                    (Math.min(api.getWidth(), api.getHeight()) / 3.3) *
                    0.6 *
                    1.6,
                  // startAngle: (0+angle) * Math.PI / 180,
                  // endAngle: (90+angle) * Math.PI / 180
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180,
                },
                style: {
                  stroke: "rgb(53,53,53)",
                  fill: " transparent",
                  lineWidth: 2,
                },
                silent: true,
              };
            },
            data: [0],
          },
          //极坐标柱状图
          {
            name: "",
            type: "bar",
            roundCap: true,
            barWidth: 4,
            showBackground: true,
            stack: "a",
            backgroundStyle: {
              color: "rgba(66, 66, 66, 0)",
            },
            data: [value, 0],
            coordinateSystem: "polar",

            itemStyle: {
              color: "#26FA37",
              shadowBlur: 8,
              shadowColor: "rgba(211,145,28,1)",
            },
          },

          //分割刻度
          {
            type: "gauge",
            radius: "80%",
            clockwise: false,
            startAngle: "90",
            endAngle: "89",
            splitNumber: 36,
            detail: {
              offsetCenter: [0, -10],
              formatter: " ",
            },
            pointer: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: [
                  [0, "#43d34e"],
                  [percent / 100, "#43d34e"],
                  [1, "#10134c"],
                ],
                width: 6,
              },
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: "rgb(99,99,99)",
                width: 2, //刻度线的宽度
              },
              length: 1, //刻度线的长度
              splitNumber: 5,
            },
            splitLine: {
              show: true,
              distance: -7,
              length: 9,
              lineStyle: {
                width: 3,
                color: "#090a51",
              },
            },
            axisLabel: {
              show: false,
            },
          },

          //仪表盘，用于显示小刻度圈
          {
            name: "统计",
            type: "gauge",
            splitNumber: 8, //刻度数量
            min: 0,
            max: 100,
            radius: "58%", //图表尺寸
            center: ["50%", "50%"],
            startAngle: 90,
            endAngle: -269.9999,
            axisLine: {
              show: false,
              lineStyle: {
                width: 0,
                shadowBlur: 0,
                color: [
                  [0, "#43d34e"],
                  [1, "#43d34e"],
                ],
              },
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: "rgb(99,99,99)",
                width: 2, //刻度线的宽度
              },
              length: 1, //刻度线的长度
              splitNumber: 5,
            },
            splitLine: {
              show: true,
              length: 0,
              lineStyle: {
                color: "rgb(99,99,99)",
              },
            },
            axisLabel: {
              show: false,
            },
            // pointer: { //仪表盘指针
            //     show: 0,
            // },
            detail: {
              borderColor: "#fff",
              shadowColor: "#fff", //默认透明
              shadowBlur: 1,
              offsetCenter: [0, "0%"], // x, y，单位px
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                color: "0",
                fontSize: 25,
              },
              formatter: "{value}",
            },
            // data: [{
            //     name: "",
            //     value: 0
            // }]
          },

          //外圈波纹效果
        ],
      };
      this.pieOption2 = option;
    },
    // 室内环境空气质量检测 湿度
    getPieOption3(humidity) {
      let value = humidity;
      let maxvalue = 100;
      let percent = (value / maxvalue) * 100;
      percent = percent.toFixed(0);
      let option = {
        animation: false,
        title: {
          text: "{a|" + value + "}\n{c|%}",
          x: "center",
          y: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: 18,
                color: "#1cb6d2",
                fontWeight: 700,
              },
              c: {
                fontSize: 12,
                color: "rgba(255, 255, 255, .5)",
                fontWeight: 700,
              },
            },
          },
        },
        polar: [
          {
            radius: ["60%", "45%"],
            center: ["50%", "50%"],
          },
        ],
        angleAxis: {
          max: maxvalue,
          show: false,
          inverse: false,
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },

        series: [
          //小弧线
          {
            name: "ring5",
            type: "custom",
            z: 1,
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r:
                    (Math.min(api.getWidth(), api.getHeight()) / 3.3) *
                    0.6 *
                    1.6,
                  // startAngle: (0+angle) * Math.PI / 180,
                  // endAngle: (90+angle) * Math.PI / 180
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180,
                },
                style: {
                  stroke: "rgb(53,53,53)",
                  fill: " transparent",
                  lineWidth: 2,
                },
                silent: true,
              };
            },
            data: [0],
          },
          //极坐标柱状图
          {
            name: "",
            type: "bar",
            roundCap: true,
            barWidth: 4,
            showBackground: true,
            stack: "a",
            backgroundStyle: {
              color: "rgba(66, 66, 66, 0)",
            },
            data: [value, 0],
            coordinateSystem: "polar",

            itemStyle: {
              color: "#1FDDFF",
              shadowBlur: 7,
              shadowColor: "rgba(211,145,28,1)",
            },
          },

          //分割刻度
          {
            type: "gauge",
            radius: "80%",
            clockwise: false,
            startAngle: "90",
            endAngle: "40",
            splitNumber: 36,
            detail: {
              offsetCenter: [0, -10],
              formatter: " ",
            },
            pointer: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: [
                  [0, "#1cb6d3"],
                  [percent / 100, "#1cb6d3"],
                  [1, "#10134c"],
                ],
                width: 6,
              },
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: "rgb(99,99,99)",
                width: 2, //刻度线的宽度
              },
              length: 1, //刻度线的长度
              splitNumber: 5,
            },
            splitLine: {
              show: true,
              distance: -7,
              length: 9,
              lineStyle: {
                width: 3,
                color: "#090a51",
              },
            },
            axisLabel: {
              show: false,
            },
          },

          //仪表盘，用于显示小刻度圈
          {
            name: "统计",
            type: "gauge",
            splitNumber: 8, //刻度数量
            min: 0,
            max: 100,
            radius: "58%", //图表尺寸
            center: ["50%", "50%"],
            startAngle: 90,
            endAngle: -269.9999,
            axisLine: {
              show: false,
              lineStyle: {
                width: 0,
                shadowBlur: 0,
                color: [
                  [0, "#1cb6d3"],
                  [1, "#1cb6d3"],
                ],
              },
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: "rgb(99,99,99)",
                width: 2, //刻度线的宽度
              },
              length: 1, //刻度线的长度
              splitNumber: 5,
            },
            splitLine: {
              show: true,
              length: 0,
              lineStyle: {
                color: "rgb(99,99,99)",
              },
            },
            axisLabel: {
              show: false,
            },
            // pointer: { //仪表盘指针
            //     show: 0,
            // },
            detail: {
              borderColor: "#fff",
              shadowColor: "#fff", //默认透明
              shadowBlur: 1,
              offsetCenter: [0, "0%"], // x, y，单位px
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                color: "0",
                fontSize: 25,
              },
              formatter: "{value}",
            },
            // data: [{
            //     name: "",
            //     value: 0
            // }]
          },

          //外圈波纹效果
        ],
      };

      //获取圆上面某点的坐标(x0,y0表示坐标，r半径，angle角度)
      function getCirlPoint(x0, y0, r, angle) {
        let x1 = x0 + r * Math.cos((angle * Math.PI) / 180);
        let y1 = y0 + r * Math.sin((angle * Math.PI) / 180);
        return {
          x: x1,
          y: y1,
        };
      }
      this.pieOption3 = option;
    },
    //启动设备趋势
    getStartDeviceTrendOption() {
      const CubeLeft = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint;
          const scale = shape.barWidth / 2;
          const deep = shape.barWidth / 4;
          const c0 = [shape.x, shape.y];
          const c1 = [shape.x - scale, shape.y - deep];
          const c2 = [xAxisPoint[0] - scale, xAxisPoint[1] - deep];
          const c3 = [xAxisPoint[0], xAxisPoint[1]];
          ctx
            .moveTo(c0[0], c0[1])
            .lineTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .closePath();
        },
      });
      const CubeRight = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint;
          const scale = shape.barWidth / 2;
          const deep = shape.barWidth / 4;
          const c1 = [shape.x, shape.y];
          const c2 = [xAxisPoint[0], xAxisPoint[1]];
          const c3 = [xAxisPoint[0] + scale, xAxisPoint[1] - deep];
          const c4 = [shape.x + scale, shape.y - deep];
          ctx
            .moveTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .lineTo(c4[0], c4[1])
            .closePath();
        },
      });
      const CubeTop = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const scale = shape.barWidth / 2;
          const deep = shape.barWidth / 4;
          const c1 = [shape.x, shape.y];
          const c2 = [shape.x + scale, shape.y - deep];
          const c3 = [shape.x, shape.y - scale];
          const c4 = [shape.x - scale, shape.y - deep];
          ctx
            .moveTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .lineTo(c4[0], c4[1])
            .closePath();
        },
      });
      echarts.graphic.registerShape("CubeLeft", CubeLeft);
      echarts.graphic.registerShape("CubeRight", CubeRight);
      echarts.graphic.registerShape("CubeTop", CubeTop);
      const newXData = this.deviceUsageStatData.xData.map((item) => {
        return (item = item + "点");
      });
      const xData = newXData;
      const seriesData = this.deviceUsageStatData.yData;
      const barWidth = 28;
      const maxData = Math.floor(
        Math.max(...this.deviceUsageStatData.yData) * 1.5
      );
      let min = Math.min.apply(null, seriesData);
      let maxValue = Math.max.apply(null, seriesData);
      let iScale = Math.pow(10, String(maxValue).length - 1);
      maxValue = Math.ceil(maxValue / iScale) * iScale;
      this.startDeviceTrendOption = {
        grid: {
          top: 60,
          left: 30,
          right: 40,
          bottom: 20,
          containLabel: true,
        },
        tooltip: {
          show: true,
        },
        xAxis: {
          type: "category",
          data: xData,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
          offset: 5,
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
          },
        },
        yAxis: {
          type: "value",
          name: "次",
          min: 0,
          max: maxData,
          nameTextStyle: {
            fontSize: 12,
            padding: [0, 0, 10, 0],
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "white",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
          },
        },
        series: [
          {
            type: "pictorialBar",
            symbol: "rect",
            barWidth: "70%",
            z: -1,
            itemStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(49, 79, 93, 0)",
                  },
                  {
                    offset: 1,
                    color: "rgba(96, 173, 255, 0.2)",
                  },
                ],
              },
              borderColor: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255,255,255, 0)",
                  },
                  {
                    offset: 1,
                    color: "rgba(255,255,255, 0.5)",
                  },
                ],
              },
            },
            tooltip: {
              show: false,
            },
            data: new Array(seriesData.length).fill(maxValue),
          },
          {
            type: "custom",
            renderItem: function (params, api) {
              const location = api.coord([api.value(0), api.value(1)]);
              return {
                type: "group",
                children: [
                  {
                    type: "CubeLeft",
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                      barWidth,
                    },
                    style: {
                      fill: "#2f5a9d",
                    },
                  },
                  {
                    type: "CubeRight",
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                      barWidth,
                    },
                    style: {
                      fill: "#2f5a9d",
                    },
                  },
                  {
                    type: "CubeTop",
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                      barWidth,
                    },
                    style: {
                      fill: "#2f5a9d",
                    },
                  },
                ],
              };
            },
            tooltip: {
              show: false,
            },
            data: new Array(seriesData.length).fill(maxValue - min),
          },
          {
            type: "custom",
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)]);
              return {
                type: "group",
                children: [
                  {
                    type: "CubeLeft",
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                      barWidth,
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: "#9bfff5",
                        },
                        {
                          offset: 1,
                          color: "#6dd2ff",
                        },
                      ]),
                    },
                  },
                  {
                    type: "CubeRight",
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                      barWidth,
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: "#9bfff5",
                        },
                        {
                          offset: 1,
                          color: "#6dd2ff",
                        },
                      ]),
                    },
                  },
                  {
                    type: "CubeTop",
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                      barWidth,
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: "#9bfff5",
                        },
                        {
                          offset: 1,
                          color: "#6dd2ff",
                        },
                      ]),
                    },
                  },
                ],
              };
            },
            data: seriesData,
          },
        ],
      };
    },
    //获取当前时间
    getCurrentDate() {
      this.currentDate = {
        day: this.$moment().format("YYYY-MM-DD"),
        week: this.$moment().format("dddd"),
        time: this.$moment().format("HH:mm:ss"),
      };
    },
    initCanvas() {
      var canvas = document.querySelector("canvas"),
        ctx = canvas.getContext("2d");
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      ctx.lineWidth = 0.3;
      ctx.strokeStyle = new Color(150).style;
      var mousePosition = {
        x: (30 * canvas.width) / 100,
        y: (30 * canvas.height) / 100,
      };
      var dots = {
        nb: 250,
        distance: 50,
        d_radius: 100,
        array: [],
      };

      function colorValue(min) {
        return Math.floor(Math.random() * 255 + min);
      }

      function createColorStyle(r, g, b) {
        return "rgba(" + r + "," + g + "," + b + ", 0.5)";
      }

      function mixComponents(comp1, weight1, comp2, weight2) {
        return (comp1 * weight1 + comp2 * weight2) / (weight1 + weight2);
      }

      function averageColorStyles(dot1, dot2) {
        var color1 = dot1.color,
          color2 = dot2.color;
        var r = mixComponents(color1.r, dot1.radius, color2.r, dot2.radius),
          g = mixComponents(color1.g, dot1.radius, color2.g, dot2.radius),
          b = mixComponents(color1.b, dot1.radius, color2.b, dot2.radius);
        return createColorStyle(Math.floor(r), Math.floor(g), Math.floor(b));
      }

      function Color(min) {
        min = min || 0;
        // this.r = colorValue(min);
        // this.g = colorValue(min);
        // this.b = colorValue(min);
        this.r = 18;
        this.g = 135;
        this.b = 204;
        this.style = createColorStyle(this.r, this.g, this.b);
      }

      function Dot() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.vx = -0.5 + Math.random();
        this.vy = -0.5 + Math.random();
        this.radius = Math.random() * 3;
        this.color = new Color();
      }
      Dot.prototype = {
        draw: function () {
          ctx.beginPath();
          ctx.fillStyle = this.color.style;
          ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
          ctx.fill();
        },
      };

      function createDots() {
        for (let i = 0; i < dots.nb; i++) {
          dots.array.push(new Dot());
        }
      }

      function moveDots() {
        for (let i = 0; i < dots.nb; i++) {
          var dot = dots.array[i];
          if (dot.y < 0 || dot.y > canvas.height) {
            dot.vx = dot.vx;
            dot.vy = -dot.vy;
          } else if (dot.x < 0 || dot.x > canvas.width) {
            dot.vx = -dot.vx;
            dot.vy = dot.vy;
          }
          dot.x += dot.vx;
          dot.y += dot.vy;
        }
      }

      function connectDots() {
        for (let i = 0; i < dots.nb; i++) {
          for (let j = 0; j < dots.nb; j++) {
            let i_dot = dots.array[i];
            let j_dot = dots.array[j];
            if (
              i_dot.x - j_dot.x < dots.distance &&
              i_dot.y - j_dot.y < dots.distance &&
              i_dot.x - j_dot.x > -dots.distance &&
              i_dot.y - j_dot.y > -dots.distance
            ) {
              if (
                i_dot.x - mousePosition.x < dots.d_radius &&
                i_dot.y - mousePosition.y < dots.d_radius &&
                i_dot.x - mousePosition.x > -dots.d_radius &&
                i_dot.y - mousePosition.y > -dots.d_radius
              ) {
                ctx.beginPath();
                ctx.strokeStyle = averageColorStyles(i_dot, j_dot);
                ctx.moveTo(i_dot.x, i_dot.y);
                ctx.lineTo(j_dot.x, j_dot.y);
                ctx.stroke();
                ctx.closePath();
              }
            }
          }
        }
      }
      function drawDots() {
        for (let i = 0; i < dots.nb; i++) {
          var dot = dots.array[i];
          dot.draw();
        }
      }
      function animateDots() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        moveDots();
        connectDots();
        drawDots();
        requestAnimationFrame(animateDots);
      }
      document.addEventListener("mousemove", (e) => {
        mousePosition.x = e.pageX;
        mousePosition.y = e.pageY;
      });
      document.addEventListener("mouseleave", (e) => {
        mousePosition.x = canvas.width / 2;
        mousePosition.y = canvas.height / 2;
      });
      createDots();
      requestAnimationFrame(animateDots);
    },
    resize() {
      this.$nextTick(() => {
        const originWidth = 1920;
        const originHeight = 1080;
        const mainWidth = window.innerWidth;
        const mainHeight = window.innerHeight;
        const scale1 = mainWidth / originWidth;
        const scale2 = mainHeight / originHeight;
        const screenDom = document.getElementById("full-container");
        screenDom.style.position = "absolute";
        screenDom.style.transformOrigin = "0 0";
        let scale = 1;
        if (scale1 < scale2) {
          scale = scale1;
        } else {
          scale = scale2;
        }

        screenDom.style.left = "50%";
        screenDom.style.top = "50%";
        screenDom.style.transform = `scale(${scale}) translate(-50%,-50%)`;
        // const left = (mainWidth-screenDom.offsetWidth)/2
        // const top = (mainHeight-screenDom.offsetHeight)/2
        // screenDom.style.left = left+'px';
        // screenDom.style.top = top+'px';
      });
    },
    // 获取人员/设备情况统计
    getTotalStat() {
      this.$http.get(this.$api.totalStat).then((res) => {
        this.totalStatData = res.data || {};
      });
    },
    // 获取空气质量
    getAirQuality() {
      this.$http.get(this.$api.airQuality, this.params).then((res) => {
        this.airQualityData = res.data || {};
        this.getPieOption1(this.airQualityData.temp); // 室内环境空气质量监测 温度
        this.getPieOption2(this.airQualityData.pm2p5); // 室内环境空气质量监测 PM2.5
        this.getPieOption3(this.airQualityData.humidity); // 室内环境空气质量监测 PM2.5
      });
    },
    // 获取近24小时人员进出情况
    getEntryStat() {
      this.$http.get(this.$api.entryStat).then((res) => {
        this.entryStatData = res.data || {};
        this.getExitChart(); // 进出人员统计
      });
    },
    // 获取近24小时设备使用情况
    getDeviceUsageStat() {
      this.$http.get(this.$api.deviceUsageStat).then((res) => {
        this.deviceUsageStatData = res.data || {};
        this.getStartDeviceTrendOption(); // 启动设备趋势
      });
    },
    // 获取用户使用详情
    getUserUsageDetail() {
      this.$http.get(this.$api.userUsageDetail).then((res) => {
        (res.data || []).forEach((item) => {
          item.timeRange =
            item.startTime + " 分" + " - " + item.endTime + " 分";
          item.useDate = item.startDate + " " + item.startTime;
          item.duration = item.duration + " Min";
          item.userName = this.enph(item.userName);
        });
        this.userUsageDetailData = res.data;
      });
    },
    // 设备使用详情
    getDeviceUsageDetail() {
      this.$http.get(this.$api.deviceUsageDetail).then((res) => {
        (res.data || []).forEach((item) => {
          item.use_time = item.time + "小时";
        });
        this.deviceUsageDetailData = res.data;
      });
    },
    // 人员信息分析
    getUserInfoStat() {
      this.$http.get(this.$api.userInfoStat).then((res) => {
        this.userInfoStatData = res.data || {};
        let colorList = ["#f77b66", "#3edce0", "#f94e76", "#018ef1", "#9e60f9"];
        this.userInfoStatData.pieData.forEach((item, index) => {
          item.itemStyle = { color: colorList[index] };
        });
        this.getInfor3dOption(this.userInfoStatData.pieData); // 人员信息分析 3d
        this.getInfroSecOption1(this.userInfoStatData.sexData); // 人员信息分析扇形 男
        this.getInfroSecOption2(this.userInfoStatData.sexData); // 人员信息分析扇形 女
      });
    },
    // 获取数据
    getData() {
      this.getTotalStat();
      this.getAirQuality();
      this.getEntryStat();
      this.getDeviceUsageStat();
      this.getUserUsageDetail();
      this.getDeviceUsageDetail();
      this.getUserInfoStat();
    },
    //手机号中间4位换成****
    enph(str) {
      if (!str) {
        return "";
      }
      const changeStr = str
        .toString()
        .replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
      return changeStr;
    },
  },
  beforeUnmount() {
    if (this.dateTimer) {
      clearInterval(this.dateTimer);
    }
    if (this.animateTimer) {
      clearInterval(this.animateTimer);
    }
  },
  created() {
    setTimeout(() => {
      this.getData();
    }, 2000);

    this.animateTimer = setInterval(() => {
      this.animate = false;
      setTimeout(() => {
        this.animate = true;
        if (this.params.id === 1) {
          this.params.id = 2;
        } else {
          this.params.id = 1;
        }
      }, 500);
      setTimeout(this.getData, 2500);
    }, 60000);
  },
  mounted() {
    const loading = this.$loading({
      lock: true,
      text: "数据加载中...",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.9)",
    });
    this.initCanvas();
    this.resize();
    this.dateTimer = setInterval(() => {
      this.getCurrentDate(); // 获取当前时间
    }, 1000);
    window.addEventListener("resize", () => {
      this.resize();
    });
    this.getMapOption(); // 获取地图数据
    setTimeout(() => {
      loading.close();
    }, 5000);
    // const that = this
    // if (this.requestAnimation1) {
    //   cancelAnimationFrame(this.requestAnimation1)
    // }
    // function draw () {
    //   angle = angle + 3
    //   that.pieOption1 = { ...that.pieOption1 }
    //   that.pieOption2 = { ...that.pieOption2 }
    //   that.pieOption3 = { ...that.pieOption3 }
    // }
    // if (this.setTempTimeout) {
    //   clearTimeout(this.setTempTimeout)
    // }
    // this.setTempTimeout = setTimeout(() => {
    //   this.requestAnimation1 = setInterval(() => {
    //     draw()
    //   }, 60)
    // }, 1000)
  },
};
</script>

<style lang="scss">
#myCanvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}
#full-container {
  position: relative;
  // transform-origin: 0 0;
  width: 1920px;
  height: 1080px;
  background: url("@/assets/img/bg.png") center/contain no-repeat;
  .full-container-header {
    position: relative;
    width: 100%;
    height: 104px;
    background: url("@/assets/img/header.png") center/contain no-repeat;
    overflow: hidden;
    z-index: 3;
    .full-container-header-logo {
      position: absolute;
      left: 24px;
      display: flex;
      align-items: center;
      height: 67px;
      img {
        width: 110px;
        object-fit: contain;
      }
    }
    .full-container-header-time {
      position: absolute;
      right: 24px;
      display: flex;
      align-items: center;
      height: 67px;
      font-size: 18px;
      font-weight: 400;
      color: #00e4ff;
      .full-container-header-time-container {
        list-style: none;
        display: flex;
        gap: 17px;
      }
    }
  }
  .full-container-body {
    position: relative;
    padding: 0 31px;
    width: 100%;
    display: flex;
    z-index: 3;
    .full-container-body-item {
      flex: 1;
    }
    .full-container-body-item:nth-child(2) {
      padding: 0 48px;
      width: 820px;
      flex: unset;
    }
  }
  .full-container-animation {
    position: absolute;
    pointer-events: none;
    margin-top: 75px;
    margin-left: 19px;
    z-index: 1;
    width: calc(100% - 15px);
    height: calc(100% - 97px);
    top: 5px;
    left: -1px;
    overflow: hidden;
    box-shadow: inset rgba(0, 0, 0, 0.3) 0 0 0 2px;
    clip-path: path(
      "M 0 0, L793 0, L810 13, L824 13, L835 24, L1057 24, L1070 13, L1087 13, L1102 0, L1890 0, L1890 129, L1872 153, L1872 806, L1890 835,L1890 990,L0 990, L0 835, L21 809,L21 153,L0 129,L0 0,L5 5, L5 129,L26 153,L26 809,L5 840, L5 980, L1885 975, 1885 835,L1867 806,L1867 153,L1885 129,L1885 5, L1102 5, L1085 18, L1071 18, L1057 29, L835 29, L823 18, L810 18, L793 5 ,L5 5 Z"
    );
    &::before {
      content: "";
      position: absolute;
      width: 250%;
      height: 250%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding-top: 20px;
      background-image: conic-gradient(
        transparent,
        rgba(#00e4ff, 1),
        transparent 30%
      );
      // background: red;
      animation: rotate 4s linear infinite;
      z-index: -2;
    }
  }
  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .pie-echarts-container {
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: -5px;
    justify-content: space-evenly;
    .pie-echarts-item {
      position: relative;
      width: 122px;
      height: 134px;
      text-align: center;
      .pie-echarts-animation {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 113px;
        height: 113px;
        border: 2px solid rgb(53, 53, 53);
        border-radius: 50%;
        animation: autorotate 5s linear infinite;
        &::before {
          position: absolute;
          top: -3px;
          left: 50%;
          transform: translate(-50%);
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: var(--color);
        }
        &::after {
          position: absolute;
          bottom: -3px;
          left: 50%;
          transform: translate(-50%);
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: var(--color);
        }
      }
      @keyframes autorotate {
        0% {
          transform: translate(-50%, -50%) rotate(0);
        }
        100% {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
      .pie-echarts-text {
        margin-top: -8px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .num-echarts-container {
    display: flex;
    justify-content: space-between;
    padding: 19px 15px 0 15px;
    .num-echarts-card1 {
      color: #2ca0db;
      font-size: 20px;
      font-weight: bold;
      .card1-text {
        margin-bottom: 10px;
      }
      .card1-bottom {
        display: flex;
        align-items: center;
        .card1-bottom-text {
          margin-left: 10px;
        }
      }
    }
    .num-echarts-card2 {
      color: #3e8ffa;
      font-size: 20px;
      font-weight: bold;
      .card2-text {
        margin-bottom: 10px;
      }
      .card2-bottom {
        display: flex;
        align-items: center;
        .card2-bottom-text {
          margin-left: 10px;
        }
      }
    }
  }
  .infor-echarts-container {
    height: 100%;
    width: 100%;
    display: flex;
    .infor-echarts-left {
      width: 240px;
      height: 100%;
      text-align: center;
      position: relative;
      .left-container {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 25px;
      }
      .content {
        position: absolute;
        top: 85%;
        left: 50%;
        width: 50px;
        height: 14px;
        transform: translateX(-50%);
        .text {
          color: #999999;
        }
      }
    }
    .infor-echarts-right {
      display: flex;
      flex: 1;
      height: 100%;
      .infor-right-man {
        height: 100%;
        width: 50%;
        text-align: center;
        position: relative;
        margin-left: 50px;
      }
      .infor-right-women {
        height: 100%;
        width: 50%;
        text-align: center;
        position: relative;
        margin-right: 40px;
      }
      .pic {
        position: absolute;
        top: 48%;
        left: 50%;
        width: 87px;
        height: 78px;
        transform: translateX(-50%);
        img {
          width: 100%;
          height: 100%;
        }
      }
      .content {
        position: absolute;
        top: 85%;
        left: 50%;
        width: 50px;
        height: 14px;
        transform: translateX(-50%);
        .text {
          color: #999999;
        }
      }
    }
  }
  .center-top {
    position: relative;
    width: 100%;
    height: 100%;
    .fjmap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50px;
      left: 0;
      .map-legend {
        position: absolute;
        top: 580px;
        right: 31px;
        .map-legend-title {
          color: #999999;
          font-size: 16px;
          padding-left: 25px;
          margin-bottom: 12px;
        }
        .map-legend-item-top {
          display: flex;
          font-size: 14px;
          align-items: center;
        }
        .map-legend-item-bottom {
          display: flex;
          font-size: 14px;
          align-items: center;
        }
        img {
          width: 27px;
          height: 36px;
          margin-right: 5px;
        }
      }
    }
    .animation-legend {
      position: absolute;
      bottom: 195px;
      left: 320px;
      width: 50px;
      height: 60px;
      animation: legendAnimation 2s linear infinite;
      img {
        width: 100%;
        height: 60px;
      }
    }
    @keyframes legendAnimation {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.35);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
</style>