<template>
  <div ref="table-scroll" class="table-scroll" :style="`--height:${height}px`">
    <!-- <table>
      <thead>
        <tr>
          <th v-for="(column, index) in columns" :key="index">{{ column.label }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="data.length>rowsPerViewport">
          <tr v-for="(row, index) in visibleRows" :key="index">
            <td v-for="(column, index) in columns" :key="index">{{ row[column.prop] }}</td>
          </tr>
        </template>
        <tr v-for="(row, index) in data" :key="index">
          <td v-for="(column, index) in columns" :key="index">{{ row[column.prop] }}</td>
        </tr>
      </tbody>
    </table> -->
    <el-table :data="data" :height="height" :border="false">
      <el-table-column
        v-for="(column, index) in columns"
        :prop="column.prop"
        :label="column.label"
        :width="column.width || 'auto'"
        :align="column.align"
        :key="index"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      default: 150,
    },
  },
  data() {
    return {
      timer: null,
    };
  },
  computed: {},
  watch: {
    data: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$nextTick(this.scrollTable);
        }
      },
    },
  },
  methods: {
    scrollTable() {
      // const table = document.querySelector(".table-scroll");
      const table = this.$refs["table-scroll"];
      let tableWrap = table.querySelector(".el-scrollbar__wrap");
      const tableBody = tableWrap.querySelector("tbody");
      const cloneContent = tableWrap.querySelector("tbody").cloneNode(true);
      tableBody.insertAdjacentHTML("beforeend", cloneContent.innerHTML);
      const scroll = () => {
        if (this.timer) {
          clearInterval(this.timer);
        }
        tableWrap = table.querySelector(".el-scrollbar__wrap");
        this.timer = setInterval(() => {
          if (
            tableWrap.scrollTop + tableWrap.clientHeight >=
            tableWrap.scrollHeight
          ) {
            tableWrap.scrollTop =
              tableWrap.scrollHeight / 2 - tableWrap.clientHeight;
          } else {
            tableWrap.scrollTop += 1;
          }
        }, 50);
      };
      scroll();
      table.addEventListener("mouseover", () => {
        if (this.timer) {
          clearInterval(this.timer);
        }
      });
      table.addEventListener("mouseleave", () => {
        scroll();
      });
    },
  },
  beforeUnmount() {
    // 在组件销毁前清除定时器
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
.table-scroll {
  overflow: hidden;
  height: var(--height);
  padding: 15px 30px 46px 30px;
  .el-table {
    background-color: transparent;
  }
  .el-table tr {
    background-color: transparent;
  }
  .el-table th.el-table__cell {
    background-color: transparent;
  }
  .el-table thead {
    background-color: transparent;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 0;
  }
  .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
    background-color: rgba(25, 139, 235, 0.6);
  }
  table {
    border-collapse: separate;
    border-spacing: 0 6px;
    width: 100%;
    thead {
      th {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.5;
        tr {
          font-size: 20px;
          padding-bottom: 25px;
        }
      }
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
      }
    }
    tbody {
      tr {
        width: 100%;
        height: 32px;
        padding-bottom: 10px;
        td {
          text-align: center;
          font-size: 14px;
        }
        td:nth-child(1) {
          color: #fff;
          font-weight: 400;
        }
        td:nth-child(2) {
          color: #42d9ff;
          font-weight: bold;
        }
        td:nth-child(3) {
          color: #5ad26b;
          font-weight: bold;
        }
      }
      tr:nth-child(odd) {
        background-color: rgba(25, 139, 235, 0.3);
      }
    }
  }
}
</style>
