import Mock from 'mockjs' //引入mockjs，npm已安装
import api from '@/api'
//全局设置：设置所有ajax请求的超时时间，模拟网络传输耗时
Mock.setup({
    // timeout: 400  //延时400ms请求到数据
    timeout: 100 //延时200-400ms请求到数据
})


// 获取人员/设备情况统计
async function mockTotalStat() {
    const json = await import('./json/mock_totalStat')
    console.log(json.default)
    Mock.mock(api.totalStat, "get", json.default)
}
mockTotalStat()

// 环境空气质量
async function mockAirQuality() {
    const json = await import('./json/mock_airQuality')
    console.log(json.default)
    Mock.mock(/\/api\/v1\/bi\/air_quality\?id=\d+/, "get", json.default)
}
mockAirQuality()

// 近24小时人员进出情况
async function mockEntryStat() {
    const json = await import('./json/mock_entryStat')
    console.log(json.default)
    Mock.mock(api.entryStat, "get", json.default)
}
mockEntryStat()

// 近24小时设备使用情况
async function mockDeviceUsageStat() {
    const json = await import('./json/mock_deviceUsageStat')
    console.log(json.default)
    Mock.mock(api.deviceUsageStat, "get", json.default)
}
mockDeviceUsageStat()

// 用户使用详情
async function mockUserUsageDetail() {
    const json = await import('./json/mock_userUsageDetail')
    console.log(json.default)
    Mock.mock(api.userUsageDetail, "get", json.default)
}
mockUserUsageDetail()

// 设备使用详情
async function mockDeviceUsageDetail() {
    const json = await import('./json/mock_deviceUsageDetail')
    console.log(json.default)
    Mock.mock(api.deviceUsageDetail, "get", json.default)
}
mockDeviceUsageDetail()

// 人员信息分析
async function mockUserInfoStat() {
    const json = await import('./json/mock_userInfoStat')
    console.log(json.default)
    Mock.mock(api.userInfoStat, "get", json.default)
}
mockUserInfoStat()