<template>
  <div class="full-container-item" :style="{'width':width,'height':height}">
    <div v-if="title" class="full-container-item-header">
      <div class="header-title ">
        <div :class="{'animate__animated':animate, 'animate__slideInRight':animate}" :style="`color:${fontColor}`">{{title}}</div>
      </div>
    </div>
    <div class="full-container-item-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: 'auto'
    },
    animate:{
      type:Boolean,
      default: false
    },
    fontColor:{
      type:String,
      default:'#42d9ff'
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
.full-container-item {
  width: 100%;
  z-index: 100;
  // border: 1px solid red;
  .full-container-item-header {
    width: 100%;
    height: 43px;
    background: url('@/assets/img/title.png') center/contain no-repeat;
    font-size: 18px;
    color: #42d9ff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .full-container-item-body {
    width: 100%;
    height: calc(100% - 43px);
  }
  .header-title{
    width: 190px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }
}
</style>