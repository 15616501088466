import axios from "axios";
import qs from "qs";
// axios.defaults.baseURL = ''  //正式
axios.defaults.baseURL = '' //测试
//post请求头
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
//设置超时
axios.defaults.timeout = 50000;
axios.interceptors.request.use(
  config => {
    // config.headers["Authorization"] = "Basic c2FiZXI6c2FiZXJfc2VjcmV0"
    // config.headers["Tenant-Id"] = "000000"
    // config.headers["Blade-Auth"] = localStorage.getItem('Access_Token')
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    if (response.status == 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    return Promise.reject(error);
  }
);
export default {
  post(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        data: qs.stringify(data),
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        });
    })
  },
  get(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params: data,
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
};