<template>
  <div class="echarts-container-container">
    <div
      :style="{ opacity: isEmpty ? '0' : '1' }"
      class="echarts-container"
      ref="echartsContainer"
    ></div>
    <div v-show="isEmpty" class="empty-text">暂无数据</div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  props: {
    option: {
      type: Object,
      default: () => ({}),
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  watch: {
    option: {
      handler(val, oldVal) {
        this.render();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    render() {
      if (!this.myChart) {
        this.myChart = echarts.init(this.$refs.echartsContainer);
      }
      this.myChart.setOption(this.option);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.render();
    });
  },
  beforeDestroy() {
    this.myChart.dispose();
  },
};
</script>

<style lang='scss'>
.echarts-container-container {
  position: relative;
  width: 100%;
  height: 100%;
  .empty-text {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #eee;
    font-size: 36px;
  }
}
.echarts-container {
  width: 100%;
  height: 100%;
}
</style>