<template>
  <div class="daily">
    <div class="daily-container">
      <div class="daily-container-item">
        <div class="daily-container-item-left">
          <div class="inner">
            <img src="../assets/img/people.png" alt />
          </div>
          <div class="circle-bg" style="--i:0s;">
            <img class="circleAnimation" src="../assets/img/circle.png" alt />
          </div>
          <div class="bottom-bg">
            <img src="../assets/img/bottom-bg.png" alt />
          </div>
        </div>
        <div class="daily-container-item-right">
          <div class="right-top">{{data.today_entry}}</div>
          <div class="right-bottom">今日进出人员</div>
        </div>
      </div>
      <div class="daily-container-item">
        <div class="daily-container-item-left">
          <div class="inner">
            <img src="../assets/img/people.png" alt />
          </div>
          <div class="circle-bg" style="--i:1s;">
            <img class="circleAnimation" src="../assets/img/circle.png" alt />
          </div>
          <div class="bottom-bg">
            <img src="../assets/img/bottom-bg.png" alt />
          </div>
        </div>
        <div class="daily-container-item-right">
          <div class="right-top">{{data.yesterday_entry}}</div>
          <div class="right-bottom">昨日进出人员</div>
        </div>
      </div>
      <div class="daily-container-item data-usage">
        <div class="daily-container-item-left">
          <div class="inner-arror">
            <img src="../assets/img/arror.png" alt />
          </div>
          <div class="circle-bg" style="--i:2s;">
            <img class="circleAnimation" src="../assets/img/circle.png" alt />
          </div>
          <div class="bottom-bg">
            <img src="../assets/img/bottom-bg.png" alt />
          </div>
        </div>
        <div class="daily-container-item-right">
          <div class="right-top">{{data.today_usage}}</div>
          <div class="right-bottom">今日启动数据</div>
        </div>
      </div>
      <div class="daily-container-item data-usage">
        <div class="daily-container-item-left">
          <div class="inner-arror">
            <img src="../assets/img/arror.png" alt />
          </div>
          <div class="circle-bg" style="--i:3s;">
            <img class="circleAnimation" src="../assets/img/circle.png" alt />
          </div>
          <div class="bottom-bg">
            <img src="../assets/img/bottom-bg.png" alt />
          </div>
        </div>
        <div class="daily-container-item-right">
          <div class="right-top">{{data.yesterday_usage}}</div>
          <div class="right-bottom">昨日启动数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped lang='scss'>
.daily {
  height: 100%;
  padding: 40px 20px 47px 30px;
  .daily-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 50%, 50%;
    grid-template-columns: 50% 50%;
    row-gap: 21px;
    column-gap: 23px;
    .daily-container-item {
      // background-color: #fff;
      display: flex;
      align-items: center;
      // justify-content: center;
      .daily-container-item-left {
        position: relative;
        margin-right: 9px;
        width: 68px;
        img {
          width: 68px;
        }
        .circle-bg {
          img {
            width: 68px;
          }

          .circleAnimation {
            animation: circleChange 2s linear calc(var(--i) / 2) infinite;
          }
        }
        .inner-arror {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-40%);
          img {
            width: 25px;
          }
        }
        .inner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          img {
            width: 25px;
          }
        }
        .bottom-bg {
          position: absolute;
          bottom: -4px;
          left: 0;
        }
      }
      @keyframes circleChange {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(-360deg);
        }
      }
      .daily-container-item-right {
        text-align: center;
        .right-top {
          color: #40e6ff;
          font-weight: 800;
          font-size: 30px;
        }
        .right-bottom {
          font-size: 16px;
        }
      }
    }
    .data-usage {
      margin-top: 10px;
    }
  }
}
</style>