<template>
  <div
    class="num-view"
    v-for="(item, index) in this.realNum"
    :key="index"
    :style="`--i:${item};
  background: ${color};`"
  >
    <div class="real-num-item">
      <div>0</div>
      <div>1</div>
      <div>2</div>
      <div>3</div>
      <div>4</div>
      <div>5</div>
      <div>6</div>
      <div>7</div>
      <div>8</div>
      <div>9</div>
      <div>0</div>
      <div>1</div>
      <div>2</div>
      <div>3</div>
      <div>4</div>
      <div>5</div>
      <div>6</div>
      <div>7</div>
      <div>8</div>
      <div>9</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    num: {
      default: 0,
    },
    color: {
      type: String,
      default: "#0da0e0",
    },
  },
  data() {
    return{}
  },
  // 计算属性
  computed: {
    realNum() {
      return `${this.num}`.split("") || 0;
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="css" scoped>
.num-view {
  --numW: 36px;
  --numH: 48px;
  display: inline-block;
  width: var(--numW);
  height: var(--numH);
  border: 1px solid #f2f2f2;
  font-weight: bold;
  margin-left: 4px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: inset rgba(0, 0, 0, 0.6) 0 0 10px 2px;
}
.real-num-item > div {
  width: var(--numW);
  height: var(--numH);
  line-height: var(--numH);
  padding-top: 1px;
  padding-right: 1px;
  text-align: center;
}
.num-view .real-num-item {
  transform: translateY(calc(var(--i) * var(--numH) * -1)) translateZ(0);
  animation: numAnimation 20s ease infinite;
  font-family: LED;
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
  transition: 3s;
}
@keyframes numAnimation {
  0%,
  20%,
  75%,
  100% {
    transform: translateY(calc(var(--i) * var(--numH) * -1)) translateZ(0);
  }
  10% {
    transform: translateY(calc((var(--i) + 5) * var(--numH) * -1)) translateZ(0);
  }
}
</style>
