
export default {
  // //设备分类概览
  // "getEquipmentTypeAndCount": "/ht-base/equipment/getEquipmentTypeAndCount",
  // //维修任务完成情况
  // "repairDisplayForThisMonth": "/ht-base/equipment/repairDisplayForThisMonth",
  // //维护保养任务完成情况
  // "maintainDisplayForThisMonth": "/ht-base/equipment/maintainDisplayForThisMonth",
  // //故障设备TOP
  // "getFaultEquipTopN": "/ht-base/equipment/getFaultEquipTopN",
  // //设备运行情况
  // "getEquipmentStatusAndCount": "/ht-base/equipment/getEquipmentStatusAndCount",
  // //设备使用数量TOP
  // "usedSparePartTopN": "/ht-base/equipment/usedSparePartTopN",
  // //数据更新时间
  // "getTimeOfNewData": "/ht-base/equipment/getTimeOfNewData",
  totalStat:"/api/v1/bi/total_stat", // 获取人员/设备情况统计
  airQuality:'/api/v1/bi/air_quality', // 环境空气质量
  entryStat:"/api/v1/bi/entry_stat", // 近24小时人员进出情况
  deviceUsageStat:'/api/v1/bi/device_usage_stat', // 近24小时设备使用情况
  userUsageDetail:'/api/v1/bi/user_usage_detail', // 用户使用详情
  deviceUsageDetail:'/api/v1/bi/device_usage_detail', // 设备使用详情
  userInfoStat:'/api/v1/bi/user_info_stat',// 人员信息分析
}
